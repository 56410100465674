import { Model } from '@vuex-orm/core'

export default class Layout extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'layout'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.number(0),
            width: this.attr(''),
            height: this.attr(''),
            display_prop_type: this.attr(''),
            adjust_page: this.boolean(false),
            tag_key: this.attr(''),
        }
    }
}