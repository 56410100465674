var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoShow),expression:"videoShow"}],staticClass:"syncc-embeddable__zoom-content--storyvideo-7bct2Odbwe",class:{
    'syncc-embeddable__zoom-content-horizontal--storyvideo-SDe3pxn1':
      _vm.isHorizontalVideo,
  },style:(_vm.styles.componentTagStyleProperty),on:{"mouseover":_vm.mouseOverVideoContainer,"mouseleave":_vm.mouseLeaveVideoContainer}},[_c('div',{staticStyle:{"position":"relative","cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.conversionVideo.apply(null, arguments)}}},[(_vm.initialPart && !_vm.storyStartRequested && !_vm.isLoadingVideo)?_c('img',{ref:"previewImgBox",staticStyle:{"width":"100%","height":"100%","border-radius":"13.6px","object-fit":"cover"},style:(_vm.styles.componentTagStyleProperty),attrs:{"src":_vm.videoThumbnailSrc},on:{"load":_vm.handleVideoThumbnailLoad}}):_vm._e(),(_vm.initialPart && !_vm.storyStartRequested && !_vm.isLoadingVideo)?_c('play-colored',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPlayBtn),expression:"showPlayBtn"}],class:{
    'syncc-embeddable__video-play-colored-btn-horizontal-vmHe722s':
      _vm.isHorizontalVideo,
    'syncc-embeddable__video-play-colored-btn-vertical-vmHe722s':
      !_vm.isHorizontalVideo,
  },attrs:{"brandingColor":_vm.brandingColor,"isMouseOverVideoContainer":_vm.isMouseOverVideoContainer,"isMouseLeaveOverVideoContainer":_vm.isMouseLeaveOverVideoContainer}}):_vm._e(),(_vm.initialPart && !_vm.storyStartRequested && !_vm.isLoadingVideo)?_c('interactive-conversation-begin-chip',{directives:[{name:"show",rawName:"v-show",value:(_vm.showPlayBtn),expression:"showPlayBtn"}],staticClass:"syncc-embeddable__begin-conversation-chip-area-4QlfeAxsaR",class:{
    'syncc-embeddable__begin-conversation-chip-area-4QlfeAxsaR':
      !this.isPageLinkVideo,
    'syncc-embeddable__begin-conversation-chip-area-page-link-content-73Bcuy3kj':
      this.isPageLinkVideo,
  }}):_vm._e()],1),((!_vm.initialPart && _vm.isLoadingVideo) ||
    (_vm.storyStartRequested && _vm.isLoadingVideo)
    )?_c('interactive-video-loading',{style:({ height: this.previewImgBoxHeight })}):_vm._e(),(!_vm.initialPart || _vm.storyStartRequested)?_c('div',{staticStyle:{"position":"relative"},style:(_vm.styles.videoContainerStyle),attrs:{"videoOrientationStyle":_vm.styles.componentTagStyleProperty}},[_c('interactive-video',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingVideo),expression:"!isLoadingVideo"}],ref:"interactiveVideo",staticClass:"syncc-embeddable__video-K8eklU93N",staticStyle:{"width":"100%","height":"100%"},style:(_vm.styles.componentTagStyleProperty),attrs:{"videoThumbnailSrc":_vm.videoThumbnailSrc,"playbackId":_vm.part.video.mux_playback_id,"jwt":_vm.part.video.mux_jwt,"initialPart":_vm.initialPart,"isHorizontal":_vm.isHorizontalVideo,"isChapterRequired":_vm.isChapterRequired,"videoOrientationStyle":_vm.styles.componentTagStyleProperty},on:{"can-play":_vm.finishLoadingAndDisplayVideo,"on-show-chapter-mode":_vm.showChapter,"on-get-video-duration":_vm.setVideoDuration,"on-update-video-process-time":_vm.handleChoiceShowTimeset,"on-process-update-watch-status":_vm.sendWatchStatusEngagementEvent}}),_c('transition',{staticStyle:{"position":"relative"},attrs:{"name":"fade"}},[(_vm.showChapterMode)?_c('div',{class:{
    'syncc-embeddalbe__chapter-container-area-horizontal-n5Nekw2snc':
      this.isHorizontalVideo,
    'syncc-embeddable__chapter-container-area-vertical-8oLXem9dma':
      !this.isHorizontalVideo,
  }},[_c('chapter',{attrs:{"nowPlayingPart":_vm.part.part_id},on:{"close-chapter":_vm.closeChapter,"on-process-update-watch-status":_vm.sendWatchStatusEngagementEvent}})],1):_vm._e()]),_c('div',{class:{
    'syncc-embeddable__on-video-overlay-container-lN3AAd82':
      !this.isPageLinkVideo,
    'syncc-embeddable__on-video-overlay-container-on-page-link-n31BDlew':
      this.isPageLinkVideo,
  }},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.messageShow),expression:"messageShow"}],staticClass:"syncc-embeddable__video-message-text-9dlPcmss"},[_c('span',{style:({ color: _vm.part.message_text_color_hex })},[_vm._v(_vm._s(_vm.part.message_text_content))])])]),_c('transition',{attrs:{"name":"fade"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.choiceShow),expression:"choiceShow"}],staticClass:"syncc-embeddable__choices-container-on-video-Nre77iuc",class:{
      'syncc-embeddable__choices-container-on-video-horizontal-dm21NOwcc':
        this.isHorizontalVideo,
      'syncc-embeddable__choices-container-on-video-vertical-dm21NOwcc':
        !this.isHorizontalVideo,
    },attrs:{"id":"choices-container"}},[_vm._l((_vm.choices),function(choice){return _c('single-choice',{key:choice.id,attrs:{"choice":choice,"themeColor":_vm.brandingColor,"isHorizontalVideo":_vm.isHorizontalVideo},on:{"next-video-part":_vm.disposeVideo,"on-process-update-watch-status":_vm.sendWatchStatusEngagementEvent,"on-process-choice-select":_vm.sendChoiceSelectEngagementEvent}})}),_vm._l((_vm.mainCTAChoices),function(choice){return _c('single-choice',{key:choice.id,attrs:{"choice":choice,"themeColor":_vm.brandingColor,"isHorizontalVideo":_vm.isHorizontalVideo},on:{"next-video-part":_vm.disposeVideo,"on-process-update-watch-status":_vm.sendWatchStatusEngagementEvent,"on-process-choice-select":_vm.sendChoiceSelectEngagementEvent}})})],2)])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingVideo),expression:"!isLoadingVideo"}],staticStyle:{"position":"absolute","bottom":"-5px","left":"50%","transform":"translateX(-50%)","z-index":"1000 !important","cursor":"pointer"},on:{"click":_vm.routeToSynccServicePage}},[_c('img',{staticStyle:{"max-width":"95px"},attrs:{"src":_vm.logo}})])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }