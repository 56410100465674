import Vue from 'vue'
import Router from 'vue-router'
import Main from "./views/Main.vue"
import StoryVideos from "./views/StoryVideos.vue"
import Thanks from "./views/Thanks.vue"


Vue.use(Router)

export default new Router({
    routes: [{
        path: '/',
        name: 'main',
        component: Main,
    },
    {
        path: '/video/:id',
        name: 'story-videos',
        component: StoryVideos
    },
    {
        path: '/thanks',
        name: 'thanks',
        component: Thanks,
    },
    ],
    mode: 'abstract',
    linkActiveClass: 'active'
})