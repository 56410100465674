import { render, staticRenderFns } from "./SingleChoice.vue?vue&type=template&id=5bab08e2&scoped=true&"
import script from "./SingleChoice.vue?vue&type=script&lang=js&"
export * from "./SingleChoice.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./SingleChoice.vue?vue&type=style&index=0&id=5bab08e2&scoped=true&lang=css&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "5bab08e2",
  null
  ,true
)

export default component.exports