<template>
  <div id="syncc-embeddable__app" class="syncc-embeddable__app">
    <async-lazy-main-component
      :widgetId="widgetId"
      :config="JSON.parse(config)"
      :width="width"
      :height="height"
      :display="display"
      :tagKey="key"
      :pageLinkContent="pageLinkContent"
    />
  </div>
</template>

<script>
import AsyncComponentLoading from "./components/UI/AsyncComponentLoading.vue";
import AsyncComponentErrorLoading from "./components/UI/AsyncComponentErrorLoading.vue";

export default {
  props: [
    "widget-id",
    "config",
    "width",
    "height",
    "display",
    "key",
    "page-link-content",
  ],
  components: {
    AsyncLazyMainComponent: () => ({
      // The component to load (should be a Promise)
      component: import("./views/Main.vue"),
      // A component to use while the async component is loading
      loading: AsyncComponentLoading,
      // A component to use if the load fails
      error: AsyncComponentErrorLoading,
      // Delay before showing the loading component. Default: 200ms.
      delay: 0,
      // The error component will be displayed if a timeout is
      // provided and exceeded. Default: Infinity.
      timeout: 3000,
    }),
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
};
</script>
