import { Model } from '@vuex-orm/core'

export default class Choice extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'choices'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.attr(''),
            part_id: this.attr(''),
            title: this.attr(''),
            display_priority: this.number(0),
            choice_type: this.attr(''),
            in_choice_text_content: this.attr(''),
            link_url: this.attr(''),
            next_part: this.attr(''),
            is_main_cta: this.boolean(false),
        }
    }
}