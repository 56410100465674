import { Model } from '@vuex-orm/core'

export default class Config extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'configs'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            icon_loop: this.boolean(true),
            allow_remove: this.boolean(true),
            answer_choice_display_timeout: this.number(3),
            trigger_urls: this.attr(['*']),
            additional_margin_bottom: this.number(0),
        }
    }
}