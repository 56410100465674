var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.choice.in_choice_text_content && _vm.choice.choice_type)?_c('div',[(_vm.choice.is_main_cta)?_c('div',{class:{
      'syncc-embeddable__chocie-hover-fm3s534Ncle': this.isMouseOverChoice,
      'syncc-embeddable__choice-hover-leave-L1qcnFrj3':
        this.isMouseLeaveOverChoice,
    },on:{"mouseover":_vm.mouseOverChoice,"mouseleave":_vm.mouseLeaveChoice}},[_c('div',{staticClass:"syncc-embeddable__choice-main-cta-0k1Po3nc",class:{
        'syncc-embeddable__choice-vertical': !this.isHorizontalVideo,
        'syncc-embeddable__choice-horizontal': this.isHorizontalVideo,
      },style:({ 'background-color': this.themeColor }),on:{"click":function($event){$event.preventDefault();return _vm.handleConversion.apply(null, arguments)}}},[_c('span',{staticClass:"syncc-embeddable__choice-btn-content-bold-oO4jsnce",style:({ color: this.mainCTABtnTextColor })},[_vm._v(" "+_vm._s(_vm.choice.in_choice_text_content))])])]):_c('div',{class:{
      'syncc-embeddable__chocie-hover-fm3s534Ncle': this.isMouseOverChoice,
      'syncc-embeddable__choice-hover-leave-L1qcnFrj3':
        this.isMouseLeaveOverChoice,
    },on:{"mouseover":_vm.mouseOverChoice,"mouseleave":_vm.mouseLeaveChoice}},[_c('div',{staticClass:"syncc-embeddable__choice-plain-g241kNei",class:{
        'syncc-embeddable__choice-vertical': !this.isHorizontalVideo,
        'syncc-embeddable__choice-horizontal': this.isHorizontalVideo,
      },on:{"click":function($event){$event.preventDefault();return _vm.handleConversion.apply(null, arguments)}}},[_c('play-original',{directives:[{name:"show",rawName:"v-show",value:(_vm.choice.choice_type == 'NEXT_PART'),expression:"choice.choice_type == 'NEXT_PART'"}],attrs:{"size":25,"color":_vm.playBtnColor}}),_c('share-arrow',{directives:[{name:"show",rawName:"v-show",value:(_vm.choice.choice_type == 'EXTERNAL_LINK'),expression:"choice.choice_type == 'EXTERNAL_LINK'"}]}),_c('approved-message',{directives:[{name:"show",rawName:"v-show",value:(_vm.choice.choice_type == 'END'),expression:"choice.choice_type == 'END'"}]}),_c('span',{staticClass:"syncc-embeddable__choice-btn-content-plain-M12ln3sc"},[_vm._v(" "+_vm._s(_vm.choice.in_choice_text_content))])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }