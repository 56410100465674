import { Model } from "@vuex-orm/core"

export default class UserEngagement extends Model {
    // This is the name used as module name of the Vuex Store.
    static entity = 'user_engagement'

    // List of all fields (schema) of the post model. `this.attr` is used
    // for the generic field type. The argument is the default value.
    static fields() {
        return {
            id: this.number(0),
            engagement_id: this.attr(''),
            impression: this.boolean(true),
            play: this.boolean(false),
            interaction: this.boolean(false),
            btn_click: this.number(0),
            complete: this.boolean(false),
            external_link_routing: this.boolean(false),
            main_cta_engaged: this.boolean(false),
            is_unique: this.boolean(true),
            os: this.attr(''),
            location: this.attr(''),
            browser: this.attr(''),
            share_type: this.attr(''),
        }
    }
}