<template>
  <div v-show="videoShow" class="syncc-embeddable__zoom-content--storyvideo-7bct2Odbwe"
    :style="styles.componentTagStyleProperty" :class="{
      'syncc-embeddable__zoom-content-horizontal--storyvideo-SDe3pxn1':
        isHorizontalVideo,
    }" v-on:mouseover="mouseOverVideoContainer" v-on:mouseleave="mouseLeaveVideoContainer">
    <div style="position: relative; cursor: pointer" v-on:click.prevent="conversionVideo">
      <img v-if="initialPart && !storyStartRequested && !isLoadingVideo" style="
          width: 100%;
          height: 100%;
          border-radius: 13.6px;
          object-fit: cover;
        " :style="styles.componentTagStyleProperty" :src="videoThumbnailSrc" ref="previewImgBox"
        v-on:load="handleVideoThumbnailLoad" />
      <play-colored v-if="initialPart && !storyStartRequested && !isLoadingVideo" v-show="showPlayBtn"
        :brandingColor="brandingColor" :isMouseOverVideoContainer="isMouseOverVideoContainer"
        :isMouseLeaveOverVideoContainer="isMouseLeaveOverVideoContainer" :class="{
      'syncc-embeddable__video-play-colored-btn-horizontal-vmHe722s':
        isHorizontalVideo,
      'syncc-embeddable__video-play-colored-btn-vertical-vmHe722s':
        !isHorizontalVideo,
    }" />
      <interactive-conversation-begin-chip v-if="initialPart && !storyStartRequested && !isLoadingVideo"
        v-show="showPlayBtn" class="syncc-embeddable__begin-conversation-chip-area-4QlfeAxsaR" :class="{
      'syncc-embeddable__begin-conversation-chip-area-4QlfeAxsaR':
        !this.isPageLinkVideo,
      'syncc-embeddable__begin-conversation-chip-area-page-link-content-73Bcuy3kj':
        this.isPageLinkVideo,
    }"></interactive-conversation-begin-chip>
    </div>
    <interactive-video-loading v-if="(!initialPart && isLoadingVideo) ||
      (storyStartRequested && isLoadingVideo)
      " :style="{ height: this.previewImgBoxHeight }"></interactive-video-loading>
    <div v-if="!initialPart || storyStartRequested" style="position: relative" :style="styles.videoContainerStyle"
      :videoOrientationStyle="styles.componentTagStyleProperty">
      <interactive-video v-show="!isLoadingVideo" style="width: 100%; height: 100%"
        :style="styles.componentTagStyleProperty" class="syncc-embeddable__video-K8eklU93N" ref="interactiveVideo"
        :videoThumbnailSrc="videoThumbnailSrc" :playbackId="part.video.mux_playback_id" :jwt="part.video.mux_jwt"
        :initialPart="initialPart" :isHorizontal="isHorizontalVideo" :isChapterRequired="isChapterRequired"
        :videoOrientationStyle="styles.componentTagStyleProperty" v-on:can-play="finishLoadingAndDisplayVideo"
        v-on:on-show-chapter-mode="showChapter" v-on:on-get-video-duration="setVideoDuration"
        v-on:on-update-video-process-time="handleChoiceShowTimeset"
        v-on:on-process-update-watch-status="sendWatchStatusEngagementEvent">
      </interactive-video>
      <transition name="fade" style="position: relative">
        <div v-if="showChapterMode" :class="{
      'syncc-embeddalbe__chapter-container-area-horizontal-n5Nekw2snc':
        this.isHorizontalVideo,
      'syncc-embeddable__chapter-container-area-vertical-8oLXem9dma':
        !this.isHorizontalVideo,
    }">
          <chapter :nowPlayingPart="part.part_id" v-on:close-chapter="closeChapter"
            v-on:on-process-update-watch-status="sendWatchStatusEngagementEvent" />
        </div>
      </transition>
      <div :class="{
      'syncc-embeddable__on-video-overlay-container-lN3AAd82':
        !this.isPageLinkVideo,
      'syncc-embeddable__on-video-overlay-container-on-page-link-n31BDlew':
        this.isPageLinkVideo,
    }">
        <transition name="fade">
          <div class="syncc-embeddable__video-message-text-9dlPcmss" v-show="messageShow">
            <span :style="{ color: part.message_text_color_hex }">{{
      part.message_text_content
    }}</span>
          </div>
        </transition>
        <transition name="fade">
          <div id="choices-container" v-show="choiceShow" class="syncc-embeddable__choices-container-on-video-Nre77iuc"
            :class="{
        'syncc-embeddable__choices-container-on-video-horizontal-dm21NOwcc':
          this.isHorizontalVideo,
        'syncc-embeddable__choices-container-on-video-vertical-dm21NOwcc':
          !this.isHorizontalVideo,
      }">
            <single-choice v-for="choice in choices" :key="choice.id" :choice="choice" :themeColor="brandingColor"
              :isHorizontalVideo="isHorizontalVideo" v-on:next-video-part="disposeVideo"
              v-on:on-process-update-watch-status="sendWatchStatusEngagementEvent
      " v-on:on-process-choice-select="sendChoiceSelectEngagementEvent"></single-choice>
            <single-choice v-for="choice in mainCTAChoices" :key="choice.id" :choice="choice"
              :themeColor="brandingColor" :isHorizontalVideo="isHorizontalVideo" v-on:next-video-part="disposeVideo"
              v-on:on-process-update-watch-status="sendWatchStatusEngagementEvent
      " v-on:on-process-choice-select="sendChoiceSelectEngagementEvent"></single-choice>
          </div>
        </transition>
      </div>
      <div v-show="!isLoadingVideo" style="
          position: absolute;
          bottom: -5px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000 !important;
          cursor: pointer;
        " @click="routeToSynccServicePage">
        <img :src="logo" style="max-width: 95px" />
      </div>
    </div>
  </div>
</template>

<script>
import Config from "../models/Config";
import Customization from "../models/Customization";
import Choice from "../models/Choice";
import Layout from "../models/Layout";
import Part from "../models/Part";
import Story from "../models/Story";
import Video from "../models/Video";
import EngagedUser from "../models/EngagedUser";
import UserEngagement from "../models/UserEngagement";
import UserVideoEngagement from "../models/UserVideoEngagement";

import Chapter from "../components/UI/Chapter.vue";
import InteractiveVideo from "../components/UI/InteractiveVideo.vue";
import InteractiveVideoLoading from "../components/UI/InteractiveVideoLoading.vue";
import SingleChoice from "../components/UI/SingleChoice.vue";

import PlayColored from "../components/UI/icons/svg/PlayColored.vue";
import InteractiveConversationBeginChip from "../components/UI/InteractiveConversationBeginChip.vue";

import store from "../store/index";

export default {
  props: {},
  components: {
    Chapter,
    InteractiveVideo,
    InteractiveVideoLoading,
    SingleChoice,
    PlayColored,
    InteractiveConversationBeginChip,
  },
  data() {
    return {
      part: Object,
      choices: Array,
      mainCTAChoices: Array,
      showAnswerChoiceTimeIntervalInSec: Number,
      videoThumbnailSrc: String,
      brandingColor: "",
      isMouseOverVideoContainer: false,
      isMouseLeaveOverVideoContainer: false,
      showPlayBtn: false,
      previewImgBoxHeight: 0,
      storyStartRequested: false,
      isLoadingVideo: false,
      initialPart: false,
      videoShow: true,
      closeBtnShow: true,
      choiceShow: false,
      messageShow: false,
      isChapterRequired: false,
      showChapterMode: false,
      isHorizontalVideo: true,
      isPageLinkVideo: false,
      styles: {
        videoContainerStyle: {},
        componentTagStyleProperty: {},
      },
      logo: "",
      themeColor: "",
      teamSlug: String,
      doneWatchProgressEngagementEventRequest: false,
      doneCreateVideoViewEngagementEventRequest: false,
    };
  },
  created() {
    /* Video handling */
    const video = Video.all()[0];
    let iconPlaybackId = video.mux_playback_id;
    let iconGifJWT = video.mux_jwt_for_gif;
    let iconImgJwt = video.mux_jwt_for_img;
    let config = Config.all()[0];
    /* Layout handling */
    const layout = Layout.all()[0];
    if (layout.height !== "") {
      this.previewImgBoxHeight = layout.height;
      this.styles.componentTagStyleProperty["height"] =
        layout.height + " !important";
    }
    if (layout.width !== "") {
      this.styles.componentTagStyleProperty["width"] =
        layout.width + " !important";
    }
    if (layout.display_prop_type) {
      this.styles.componentTagStyleProperty["display"] =
        layout.display_prop_type;
    }
    if (layout.adjust_page) {
      this.isPageLinkVideo = true;
    }

    /* Part and choice handling */
    const partId = this.$route.params["id"];
    this.initialPart = this.$route.query["initial"];
    if (!this.initialPart) {
      this.isLoadingVideo = true;
    }

    this.part = Part.query().with("video").where("part_id", partId).get()[0];
    this.choices = Choice.query()
      .where("part_id", partId)
      .where("is_main_cta", false)
      .orderBy("display_priority", "asc")
      .get();
    this.mainCTAChoices = Choice.query()
      .where("part_id", partId)
      .where("is_main_cta", true)
      .orderBy("display_priority", "asc")
      .get();

    this.showAnswerChoiceTimeIntervalInSec = this.part.choices_display_timeout;

    this.styles.videoContainerStyle = {
      borderRadius: "2rem",
      display: "block",
      "-webkit-user-select": "none",
      margin: "auto",
      "z-index": "998 !important",
    };

    /* Chapter handling */
    let story = Story.all()[0];
    this.teamSlug = story.team_slug;
    this.isChapterRequired = story.is_chapter_required;
    if (config.icon_loop) {
      this.videoThumbnailSrc =
        "https://image.mux.com/" +
        iconPlaybackId +
        "/animated.gif?fps=15&width=640&height=640&start=2.5&token=" +
        iconGifJWT;
    } else {
      this.videoThumbnailSrc =
        "https://image.mux.com/" + iconPlaybackId + "?token=" + iconImgJwt;
    }

    if (story.thumbnail) {
      this.videoThumbnailSrc = story.thumbnail;
    }

    /* Design Customization handling */
    let customization = Customization.all()[0];
    this.isHorizontalVideo =
      customization.video_orientation == "HORIZONTAL_VIDEO";
    this.brandingColor = customization.theme_color;

    /* Syncc brand logo handling */
    const isProduction = process.env.NODE_ENV === "production";
    if (isProduction) {
      this.logo = "https://syncc-embeddable.web.app/img/syncc-logo.png";
    } else {
      this.logo = require("@/assets/img/syncc-logo.png");
    }
  },
  mounted() {
    window.addEventListener(
      "beforeunload",
      this.handleWatchProgressPatchEventRequest
    );
  },
  beforeDestroy() { },
  watch: {},
  computed: {},
  methods: {
    mouseOverVideoContainer() {
      this.isMouseOverVideoContainer = true;
      this.isMouseLeaveOverVideoContainer = false;
    },
    mouseLeaveVideoContainer() {
      this.isMouseOverVideoContainer = false;
      this.isMouseLeaveOverVideoContainer = true;
      setTimeout(() => {
        this.isMouseLeaveOverVideoContainer = false;
      }, 1000);
    },
    async conversionVideo() {
      this.storyStartRequested = true;
      this.isLoadingVideo = true;
      let engagedUser = EngagedUser.all()[0];
      if (!engagedUser.engaged_user_id) {
        const { newUser, engagedUserId } = await this.getEngageUser(
          this.teamSlug
        );
        EngagedUser.update({
          where: "0",
          data: {
            newUser: newUser,
            engagedUserId: engagedUserId,
          },
        });
        let engagement = UserEngagement.all()[0];
        this.sendUpdateEngagementWithEngagedUser(
          engagement.engagement_id,
          engagedUserId
        );
      }
    },
    handleVideoThumbnailLoad() {
      this.showPlayBtn = true;
      this.matchHeight();
    },
    matchHeight() {
      // 暫定の処置、後ほど修正
      let _height = this.$refs.previewImgBox.clientHeight == 0 ? 354 : this.$refs.previewImgBox.clientHeight
      this.previewImgBoxHeight = _height + "px";
      const previewImgBoxWidth = this.$refs.previewImgBox.clientWidth + "px";
      Layout.update({
        where: "0",
        data: {
          height: this.previewImgBoxHeight,
          width: previewImgBoxWidth,
        },
      });
    },
    finishLoadingAndDisplayVideo() {
      this.isLoadingVideo = false;
      this.storyStartRequested = true;
      if (!this.doneCreateVideoViewEngagementEventRequest) {
        this.sendVideoViewStartPartEngagementEvent();
      }
    },
    setVideoDuration(duration) {
      UserVideoEngagement.update({
        data: {
          id: 0,
          current_engaging_video_duration: parseInt(duration),
        },
      });
    },
    handleChoiceShowTimeset(currentTime) {
      if (
        currentTime > this.showAnswerChoiceTimeIntervalInSec &&
        !this.choiceShow
      ) {
        this.choiceShow = true;
        this.messageShow = true;
      }
      if (currentTime) {
        let videoEngagement = UserVideoEngagement.all()[0];
        let videoWatchingSeconds =
          videoEngagement.current_engaging_video_watching_seconds;
        if (currentTime > videoWatchingSeconds) {
          UserVideoEngagement.update({
            data: {
              id: 0,
              current_engaging_video_watching_seconds: parseInt(currentTime),
            },
          });
        }
      }
    },
    showChapter() {
      this.showChapterMode = true;
    },
    closeChapter() {
      this.showChapterMode = false;
    },

    closeVideoContainer() {
      this.disposeVideo();
      this.$router.push({ name: "story-icon" });
    },
    disposeVideo() {
      this.$refs.interactiveVideo.disposeVideo();
    },
    handleWatchProgressPatchEventRequest() {
      if (!this.doneWatchProgressEngagementEventRequest) {
        this.sendWatchStatusEngagementEvent();
      }
    },
    sendVideoViewStartPartEngagementEvent() {
      if (this.doneCreateVideoViewEngagementEventRequest) {
        return;
      } else {
        this.doneCreateVideoViewEngagementEventRequest = true;
        let engagement = UserEngagement.all()[0];
        const payload = {
          partId: this.part.part_id,
          engagementId: engagement.engagement_id,
          eventType: "VIDEO_VIEW_STARTED",
          selctedChoiceLabel: "",
          choiceLabels: [],
          btnClick: 1,
          timeSpentInSeconds: 0,
          videoPercentageWatched: 0,
        };
        store
          .dispatch("createPartEngagementEvent", payload)
          .then((response) => {
            EngagedUser.update({
              where: "0",
              data: {
                current_engaging_part_event_id:
                  response.data.part_engagement_id,
              },
            });
          });
      }
    },
    routeToSynccServicePage() {
      const serviceUrl = "https://syncc.unstack.website/";
      window.open(serviceUrl, "_blank", "noopener");
    },
    sendUpdateEngagementWithEngagedUser(engagementId, engagedUserId) {
      const payload = {
        engagementId: engagementId,
        engagedUserId: engagedUserId,
      };
      store.dispatch("updateEngagementWithEngagedUser", payload);
    },
    sendWatchStatusEngagementEvent() {
      this.doneWatchProgressEngagementEventRequest = true;
      let engagedUser = EngagedUser.all()[0];
      let videoEngagement = UserVideoEngagement.all()[0];
      var videoPercentageWatched =
        (videoEngagement.current_engaging_video_watching_seconds /
          videoEngagement.current_engaging_video_duration) *
        100;
      const payload = {
        partEngagementId: engagedUser.current_engaging_part_event_id,
        timeSpentInSeconds:
          videoEngagement.current_engaging_video_watching_seconds,
        videoPercentageWatched: parseInt(videoPercentageWatched),
      };
      store
        .dispatch("setVideoWatchStatsOnPartEngagementEvent", payload)
        .then((_) => {
          UserVideoEngagement.update({
            data: {
              id: 0,
              current_engaging_video_watching_seconds: 0,
            },
          });
        });
    },
    sendChoiceSelectEngagementEvent({ eventType, selectedChoiceLabel }) {
      let engagement = UserEngagement.all()[0];
      let allChoices = this.choices.concat(this.mainCTAChoices);
      let choiceLabelsArr = allChoices.map(
        (choice) => choice.in_choice_text_content
      );
      const payload = {
        partId: this.part.part_id,
        engagementId: engagement.engagement_id,
        eventType: eventType,
        selctedChoiceLabel: selectedChoiceLabel,
        choiceLabels: choiceLabelsArr,
        btnClick: 1,
        timeSpentInSeconds: 0,
        videoPercentageWatched: 0,
      };
      store.dispatch("createPartEngagementEvent", payload);
    },
    resolveUserFromViewerDataArr(viewerData, teamSlug) {
      if (viewerData) {
        for (var i = 0; i < viewerData.length; i++) {
          if (viewerData[i].team_slug == teamSlug) {
            return {
              isExistingEngagedUser: true,
              engagedUserId: viewerData[i].viewer_id,
            };
          }
          if (
            i === viewerData.length - 1 &&
            viewerData[i].team_slug !== teamSlug
          ) {
            return {
              isExistingEngagedUser: false,
              engagedUserId: "",
            };
          }
        }
      } else {
        return {
          isExistingEngagedUser: false,
          engagedUserId: "",
        };
      }
    },
    async getEngageUser(teamSlug) {
      var viewerDataArr = JSON.parse(localStorage.getItem("synccViewerData"));
      const { isExistingEngagedUser, engagedUserId } =
        this.resolveUserFromViewerDataArr(viewerDataArr, teamSlug);

      if (isExistingEngagedUser) {
        return {
          newUser: false,
          engagedUserId: engagedUserId,
        };
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const personId = urlParams.get("person")
          ? urlParams.get("person")
          : null;
        var _engagedUserId = "";
        if (personId) {
          const payload = {
            teamSlug: teamSlug,
            personId: personId,
          };
          await store
            .dispatch("createEngagedUser", payload)
            .then((response) => {
              _engagedUserId = response.data.engaged_user_id;
              if (viewerDataArr) {
                viewerDataArr.push({
                  team_slug: teamSlug,
                  viewer_id: _engagedUserId,
                });
                localStorage.setItem(
                  "synccViewerData",
                  JSON.stringify(viewerDataArr)
                );
              } else {
                const newViewerDataArr = [
                  {
                    team_slug: teamSlug,
                    viewer_id: _engagedUserId,
                  },
                ];
                localStorage.setItem(
                  "synccViewerData",
                  JSON.stringify(newViewerDataArr)
                );
              }
            });
          return {
            newUser: true,
            engagedUserId: _engagedUserId,
          };
        } else {
          const payload = {
            teamSlug: teamSlug,
          };
          await store
            .dispatch("createAnonymousEngagedUser", payload)
            .then((response) => {
              _engagedUserId = response.data.engaged_user_id;
              if (viewerDataArr) {
                viewerDataArr.push({
                  team_slug: teamSlug,
                  viewer_id: _engagedUserId,
                });
                localStorage.setItem(
                  "synccViewerData",
                  JSON.stringify(viewerDataArr)
                );
              } else {
                const newViewerDataArr = [
                  {
                    team_slug: teamSlug,
                    viewer_id: _engagedUserId,
                  },
                ];
                localStorage.setItem(
                  "synccViewerData",
                  JSON.stringify(newViewerDataArr)
                );
              }
            });
          return {
            newUser: true,
            engagedUserId: _engagedUserId,
          };
        }
      }
    },
  },
};
</script>


<style scoped>
.syncc-embeddable__zoom-content--storyvideo-7bct2Odbwe {
  zoom: 100%;
}

.syncc-embeddable__zoom-content-horizontal--storyvideo-SDe3pxn1 {
  zoom: 100%;
}

@-moz-document url-prefix() {

  /* For firefox */
  .syncc-embeddable__zoom-content--storyvideo-7bct2Odbwe {
    transform-origin: 0 0;
    transform: scale(1);
  }
}

@media screen and (max-width: 767px) {
  .syncc-embeddable__zoom-content--storyvideo-7bct2Odbwe {
    zoom: 96%;
  }

  .syncc-embeddable__zoom-content-horizontal--storyvideo-SDe3pxn1 {
    zoom: 56%;
  }

  @-moz-document url-prefix() {

    /* For firefox */
    .syncc-embeddable__zoom-content--storyvideo-7bct2Odbwe {
      transform-origin: 0 0;
      transform: scale(0.96);
    }

    .syncc-embeddable__zoom-content-horizontal--storyvideo-SDe3pxn1 {
      transform-origin: 0 0;
      transform: scale(0.56);
    }
  }
}

.syncc-embeddable__video-K8eklU93N {
  background-color: hsl(0, 0%, 90%);
  transition: background-color 300ms;
  object-fit: cover;
  width: 640px !important;
  height: 360px;
  z-index: 996 !important;
  cursor: pointer;
}

.syncc-embeddable__on-video-overlay-container-lN3AAd82 {
  position: absolute;
  width: 100%;
  bottom: 0.3rem !important;
  left: 0 !important;
  align-items: center;
  text-align: center;
}

.syncc-embeddable__video-message-text-9dlPcmss {
  font-size: 1.4rem;
  margin-bottom: 0.9rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  z-index: 999 !important;
}

.syncc-embeddable__choices-container-on-video-Nre77iuc {
  z-index: 997 !important;
  -webkit-box-pack: center;
  -webkit-box-align: stretch;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
  max-height: 300px;
  overflow-y: auto;
  padding-bottom: 15px;
}

.syncc-embeddable__choices-container-on-video-horizontal-dm21NOwcc {
  display: flex;
  flex-flow: row wrap;
}

.syncc-embeddable__choices-container-on-video-vertical-dm21NOwcc {
  white-space: normal;
  width: 100%;
  max-width: 100%;
  justify-items: stretch;
  margin-left: 25px;
  margin-right: 25px;
}

.syncc-embeddable__video-close-btn-Ake8ck3 {
  position: absolute;
  top: 5px !important;
  right: 4px !important;
  z-index: 998 !important;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>