<template>
  <div
    id="interactiveVideoContainer"
    class="syncc-embeddable__interactive-video-container-ml238skcl"
    :style="videoOrientationStyle"
    ref="videoContainer"
  >
    <media-controller
      :key="playbackId"
      class="syncc-embeddable__media-controller-Q18nSj38d"
    >
      <div class="syncc-embeddable__gradient-top-9Ndo12djx"></div>
      <mux-video
        :id="videoElId"
        slot="media"
        preload="auto"
        :class="{
          'mux-video__horizontal': isHorizontal,
          'mux-video__vertical': !isHorizontal,
        }"
        :playback-id="muxSrc"
        :poster="videoThumbnailSrc"
        metadata-video-title="Sync embeddable video"
        metadata-viewer-user-id="user-id-1234"
        stream-type="on-demand"
        prefer-mse
        autoplay
        playsinline
        muted
        @canplay="switchToVideoView"
        @timeupdate="emitTimeUpdate"
      ></mux-video>
      <media-control-bar
        class="syncc-embeddable__media-controller-bar-cm98dowldss"
      >
        <div class="disable-background">
          <button
            v-if="!initialPart"
            class="media-controller__custom-button"
            @click.prevent="backToLastPart"
          >
            <svg
              width="21"
              height="21"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Warstwa_3"
              x="0px"
              y="0px"
              viewBox="0 0 430 430"
              style="enable-background: new 0 0 430 430"
              xml:space="preserve"
            >
              <path
                stroke="#fff"
                stroke-width="25"
                d="M306.78,405.03c-1.66,0-3.31-0.63-4.58-1.9L118.65,219.58c-1.21-1.21-1.9-2.86-1.9-4.58  c0-1.72,0.68-3.36,1.9-4.58L302.2,26.87c2.53-2.53,6.63-2.53,9.16,0c2.53,2.53,2.53,6.63,0,9.16L132.38,215l178.97,178.98  c2.53,2.53,2.53,6.63,0,9.16C310.09,404.39,308.43,405.03,306.78,405.03z"
              />
            </svg>
          </button>
          <media-play-button></media-play-button>
          <media-time-range :style="mediaTimeRangeStyle"></media-time-range>
          <media-playback-rate-button></media-playback-rate-button>
        </div>
        <div class="disable-background" style="margin-right: 3px">
          <media-mute-button></media-mute-button>
          <button
            id="chapterBtn"
            v-if="isChapterRequired"
            class="media-controller__custom-button"
            @click.prevent="showChapter"
          >
            <svg
              width="21"
              height="21"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              version="1.1"
              id="Warstwa_3"
              x="0px"
              y="0px"
              viewBox="0 0 430 430"
              style="enable-background: new 0 0 430 430"
              xml:space="preserve"
            >
              <path
                stroke="#fff"
                stroke-width="25"
                d="M352.16,121.24H154.88c-3.31,0-6-2.69-6-6s2.69-6,6-6h197.28c3.31,0,6,2.69,6,6S355.47,121.24,352.16,121.24z"
              />
              <path
                stroke="#fff"
                stroke-width="25"
                d="M275.56,320.76H78.28c-3.31,0-6-2.69-6-6s2.69-6,6-6h197.28c3.31,0,6,2.69,6,6S278.87,320.76,275.56,320.76z"
              />
              <path
                stroke="#fff"
                stroke-width="25"
                d="M352.16,221.24H77.84c-3.31,0-6-2.69-6-6s2.69-6,6-6h274.32c3.31,0,6,2.69,6,6S355.47,221.24,352.16,221.24z"
              />
            </svg>
          </button>
        </div>
      </media-control-bar>
    </media-controller>
  </div>
</template>


<script>
import "media-chrome";
import "@mux/mux-video";

import UserEngagement from "../../models/UserEngagement";
import Layout from "../../models/Layout";

export default {
  props: {
    playbackId: { type: String, required: true },
    videoThumbnailSrc: { type: String, required: true },
    jwt: { type: String, required: true },
    initialPart: { type: Boolean, required: true },
    isHorizontal: { type: Boolean, required: true },
    isChapterRequired: { type: Boolean, required: true },
    videoOrientationStyle: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      videoElId: "",
      muxSrc: String,
      chaperShowMode: false,
      videoEl: null,
      mediaTimeRangeStyle: {},
      tagKey: "",
    };
  },
  created() {
    this.videoElId = "video-" + this.playbackId + this.getRandomInt(256);
    this.muxSrc = this.playbackId + "?token=" + this.jwt;

    const layout = Layout.all()[0];
    this.tagKey = layout.tag_key;
    if (layout.width) {
      const widthInt = parseInt(layout.width);
      if (this.initialPart) {
        this.mediaTimeRangeStyle = {
          width: Math.ceil(widthInt * 0.5) + "px !important",
        };
      } else {
        this.mediaTimeRangeStyle = {
          width: Math.ceil(widthInt * 0.42) + "px !important",
        };
      }
    }
  },
  mounted() {
    const videoElIdSelector = "#" + this.videoElId;
    if (this.tagKey) {
      const embeddableEls = document.querySelectorAll("syncc-embeddable");
      embeddableEls.forEach((el) => {
        if (el.key == this.tagKey) {
          this.videoEl = el.shadowRoot.querySelector(videoElIdSelector);
        }
      });
    } else {
      this.videoEl = document
        .querySelectorAll("syncc-embeddable")[0]
        .shadowRoot.querySelector(videoElIdSelector);
    }
  },
  beforeDestroy() {
    // TODO: パフォーマンスに問題ある場合、以下を利用してください
    // this.disposeVideo();
  },
  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },
    emitVideoDuration() {
      this.$emit("on-get-video-duration", this.videoEl.duration);
    },
    switchToVideoView() {
      this.emitVideoDuration();
      this.$emit("can-play");
    },
    emitTimeUpdate() {
      this.$emit("on-update-video-process-time", this.videoEl.currentTime);
    },
    backToLastPart() {
      this.$emit("on-process-update-watch-status");
      this.$router.go(-1);
      UserEngagement.update({
        where: "0",
        data: {
          btn_click: true,
        },
      });
    },
    showChapter() {
      this.$emit("on-show-chapter-mode");
    },
    disposeVideo() {
      // FiIXME: This function causes error: shadowRoot is not defined.
      /*
      var videoPlayerEl = shadowRoot.getElementById("video");
      videoPlayerEl.pause();
      videoPlayerEl.removeAttribute("playback-id"); // empty source
      videoPlayerEl.load();
      */
    },
  },
};
</script>


<style scoped>
.mux-video__horizontal::part(video) {
  object-fit: cover;
  border-radius: 0.852rem;
  width: 100%;
  height: 100%;
}
.mux-video__vertical::part(video) {
  object-fit: cover;
  border-radius: 0.852rem;
  width: 100%;
  height: 100%;
}
.syncc-embeddable__interactive-video-container-ml238skcl {
  border-radius: 0.852rem;
}
.syncc-embeddable__media-controller-Q18nSj38d {
  border-radius: 0.852rem;
  background-color: #ffffff00;
}
.syncc-embeddable__media-controller-bar-cm98dowldss {
  position: absolute;
  top: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.disable-background > * {
  background: none !important;
}
.media-controller__custom-button {
  border: none;
  -webkit-user-select: none;
  line-height: 0;
  --media-loading-icon-width: 44px;
  -webkit-box-direction: normal;
  display: inline-block;
  height: auto;
  width: auto;
  box-sizing: border-box;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;

  text-align: center;
  transition: background 0.15s linear;
  pointer-events: auto;
  cursor: pointer;
  font-family: Arial, sans-serif;
  vertical-align: middle;
  background: none !important;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>