<template>
  <div
    class="syncc-embeddable__zoom-content--thanks-cYec1Lwe4"
    :class="{
      'syncc-embeddable__zoom-content-horizontal--thanks-7oOexnqx4':
        isHorizontalVideo,
    }"
  >
    <div
      class="syncc-embeddable__thanks-container-Brdoe29d"
      :style="widgetContainerStyle"
    >
      <div
        style="
          margin-left: 40px;
          margin-right: 40px;
          align-items: center;
          justify-content: center;
        "
      >
        <div
          style="
            color: #ffffff;
            text-align: center;
            font-size: 16px;
            -webkit-text-size-adjust: auto;
          "
        >
          このストーリーを最後までご覧いただきありがとうございます👏
        </div>
        <div
          style="
            margin-top: 14px;
            display: flex;
            lign-items: center;
            justify-content: center;
          "
        >
          <div style="margin: 20px; text-align: center">
            <button
              class="syncc-embeddable__thanks-page-action-btn-31VkOEcn"
              style="box-sizing: content-box; padding: 0; cursor: pointer"
              @click.prevent="backToPrevious"
            >
              <back style="margin-left: 10px; margin-top: 24px" />
            </button>
            <div style="color: #ffffff; margin-top: 7px; font-size: 0.8rem">
              戻る
            </div>
          </div>
          <div style="margin: 20px; text-align: center">
            <button
              class="syncc-embeddable__thanks-page-action-btn-31VkOEcn"
              style="box-sizing: content-box; padding: 0; cursor: pointer"
              @click.prevent="restartStory"
            >
              <play style="margin-left: 13px; margin-top: 23px" />
            </button>
            <div style="color: #ffffff; margin-top: 7px; font-size: 0.8rem">
              はじめから再生
            </div>
          </div>
          <div style="margin: 20px; text-align: center">
            <button
              class="syncc-embeddable__thanks-page-action-btn-31VkOEcn"
              style="box-sizing: content-box; padding: 0; cursor: pointer"
              @click.prevent="sharePage"
            >
              <share style="margin-left: 10px; margin-top: 20px" />
            </button>
            <div style="color: #ffffff; margin-top: 7px; font-size: 0.8rem">
              シェア
            </div>
          </div>
        </div>
      </div>
      <div
        v-show="shareUrlCopyDone"
        style="
          align-items: center;
          cursor: default;
          display: inline-flex;
          line-height: 20px;
          max-width: 100%;
          outline: none;
          overflow: hidden;
          padding: 0 12px;
          position: relative;
          text-decoration: none;
          transition-duration: 0.28s;
          transition-property: box-shadow, opacity;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          vertical-align: middle;
          white-space: nowrap;
        "
      >
        <div
          style="
            align-items: center;
            display: inline-flex;
            height: 100%;
            max-width: 100%;
            font-size: 12px;
            -webkit-text-size-adjust: auto;
            color: #ffffff;
          "
        >
          シェアリンクをコピーしました！
        </div>
      </div>
      <div
        style="
          width: 100%;
          border-top: 0.8px solid #bbb;
          margin-top: 10px;
          margin-bottom: 15px;
        "
      ></div>

      <div
        style="margin: 10px; cursor: pointer"
        @click="routeToSynccServicePage"
      >
        <img :src="logoByProduct" style="max-width: 130px" />
      </div>
    </div>
  </div>
</template>

<script>
import Part from "../models/Part";
import Customization from "../models/Customization";
import Layout from "../models/Layout";

import Back from "../components/UI/icons/svg/Back.vue";
import Play from "../components/UI/icons/svg/Play.vue";
import Share from "../components/UI/icons/svg/Share.vue";
import CloseBtn from "../components/UI/CloseBtn.vue";

export default {
  props: {},
  components: {
    CloseBtn,
    Back,
    Play,
    Share,
  },
  data() {
    return {
      closeBtnShow: true,
      isHorizontalVideo: true,
      widgetContainerStyle: {},
      shareUrlCopyDone: false,
      logoByProduct: "",
    };
  },
  created() {
    let customization = Customization.all()[0];
    this.isHorizontalVideo =
      customization.video_orientation == "HORIZONTAL_VIDEO";

    /*
    const videoOrientationStyle =
      customization.video_orientation == "HORIZONTAL_VIDEO"
        ? { width: "640px !important", height: "360px" }
        : { width: "360px !important", height: "640px" };

    this.widgetContainerStyle = {
      ...videoOrientationStyle,
    };
    */

    const layout = Layout.all()[0];
    if (layout) {
      this.widgetContainerStyle = {
        width: String(layout.width),
        height: String(layout.height),
      };
    } else {
      this.widgetContainerStyle = {
        width: "100%",
        height: "100%",
      };
    }

    const isProduction = process.env.NODE_ENV === "production";
    if (isProduction) {
      this.logoByProduct =
        "https://syncc-embeddable.web.app/img/syncc-logo-by-product.png";
    } else {
      this.logoByProduct = require("@/assets/img/syncc-logo-by-product.png");
    }
  },
  mounted() {},
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    closeVideoContainer() {
      this.$router.push({ name: "story-icon" });
    },
    backToPrevious() {
      this.$router.go(-1);
    },
    restartStory() {
      const initialPartId = Part.all()[0].part_id;
      this.$router.push({
        name: "story-videos",
        params: { id: initialPartId },
        query: { initial: true },
      });
    },
    sharePage() {
      navigator.clipboard.writeText(window.location.href);
      this.shareUrlCopyDone = true;
      setTimeout(() => {
        this.shareUrlCopyDone = false;
      }, 3000);
    },
    routeToSynccServicePage() {
      const serviceUrl = "https://syncc.unstack.website/";
      window.open(serviceUrl, "_blank", "noopener");
    },
  },
};
</script>


<style scoped>
.syncc-embeddable__thanks-page-action-btn-31VkOEcn {
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  border: none;
  background-color: #2f3137;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}

.syncc-embeddable__thanks-container-Brdoe29d {
  -webkit-user-select: none;
  border-radius: 0.852rem;
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  letter-spacing: 0.0892857143em;
  background-color: rgba(20, 20, 20, 0.99);
  transition: background-color 300ms;
  object-fit: cover;
  z-index: 999 !important;
  cursor: pointer;
  align-items: center;
}

.syncc-embeddable__zoom-content--thanks-cYec1Lwe4 {
  zoom: 100%;
}
.syncc-embeddable__zoom-content-horizontal--thanks-7oOexnqx4 {
  zoom: 100%;
}
@-moz-document url-prefix() {
  /* For firefox */
  .syncc-embeddable__zoom-content--thanks-cYec1Lwe4 {
    transform-origin: 0 0;
    transform: scale(1);
  }
}

@media screen and (max-width: 767px) {
  .syncc-embeddable__zoom-content--thanks-cYec1Lwe4 {
    zoom: 96%;
  }
  .syncc-embeddable__zoom-content-horizontal--thanks-7oOexnqx4 {
    zoom: 56%;
  }
  @-moz-document url-prefix() {
    /* For firefox */
    .syncc-embeddable__zoom-content--thanks-cYec1Lwe4 {
      transform-origin: 0 0;
      transform: scale(0.96);
    }
    .syncc-embeddable__zoom-content-horizontal--thanks-7oOexnqx4 {
      transform-origin: 0 0;
      transform: scale(0.56);
    }
  }
}
.syncc-embeddable__video-close-btn-Ake8ck3 {
  position: absolute;
  top: 5px !important;
  right: 4px !important;
  z-index: 998 !important;
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>