<template>
  <svg
    width="70"
    height="70"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    id="Warstwa_3"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background: new 0 0 512 512"
    xml:space="preserve"
    :class="{
      'syncc-embeddable__video-container-hover-2zaqKe3ng':
        this.isMouseOverVideoContainer,
      'syncc-embeddable__video-container-hover-leave-38VnxoDtk':
        this.isMouseLeaveOverVideoContainer,
    }"
  >
    <g>
      <g>
        <path
          :style="playPrimaryClass"
          d="M256,0C114.511,0,0,114.497,0,256c0,141.49,114.495,256,256,256c141.49,0,256-114.497,256-256C512,114.51,397.503,0,256,0
			z M348.238,284.418l-120.294,69.507c-10.148,5.864-22.661,5.874-32.826,0.009c-10.158-5.862-16.415-16.699-16.415-28.426V186.493
			c0-11.728,6.258-22.564,16.415-28.426c5.076-2.93,10.741-4.395,16.406-4.395c5.67,0,11.341,1.468,16.42,4.402l120.295,69.507
			c10.149,5.864,16.4,16.696,16.4,28.418C364.639,267.722,358.387,278.553,348.238,284.418z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    brandingColor: { type: String, required: false },
    isMouseOverVideoContainer: { type: Boolean, required: true },
    isMouseLeaveOverVideoContainer: { type: Boolean, required: true },
  },
  data() {
    return {
      playPrimaryClass: Object,
      playSecondaryClass: Object,
    };
  },
  created() {
    let colorRGBA = this.hexToRGB(this.brandingColor, "0.70");
    this.playPrimaryClass = {
      fill: colorRGBA,
      "stroke-width": 12.25,
      "stroke-miterlimit": 14,
    };
  },
  methods: {
    hexToRGB(hex, alpha) {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);

      if (alpha) {
        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
      } else {
        return `rgb(${r}, ${g}, ${b})`;
      }
    },
  },
};
</script>
