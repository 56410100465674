<template>
  <div v-if="choice.in_choice_text_content && choice.choice_type">
    <div
      v-if="choice.is_main_cta"
      :class="{
        'syncc-embeddable__chocie-hover-fm3s534Ncle': this.isMouseOverChoice,
        'syncc-embeddable__choice-hover-leave-L1qcnFrj3':
          this.isMouseLeaveOverChoice,
      }"
      v-on:mouseover="mouseOverChoice"
      v-on:mouseleave="mouseLeaveChoice"
    >
      <div
        :class="{
          'syncc-embeddable__choice-vertical': !this.isHorizontalVideo,
          'syncc-embeddable__choice-horizontal': this.isHorizontalVideo,
        }"
        class="syncc-embeddable__choice-main-cta-0k1Po3nc"
        :style="{ 'background-color': this.themeColor }"
        @click.prevent="handleConversion"
      >
        <span
          class="syncc-embeddable__choice-btn-content-bold-oO4jsnce"
          :style="{ color: this.mainCTABtnTextColor }"
        >
          {{ choice.in_choice_text_content }}</span
        >
      </div>
    </div>
    <div
      v-else
      :class="{
        'syncc-embeddable__chocie-hover-fm3s534Ncle': this.isMouseOverChoice,
        'syncc-embeddable__choice-hover-leave-L1qcnFrj3':
          this.isMouseLeaveOverChoice,
      }"
      v-on:mouseover="mouseOverChoice"
      v-on:mouseleave="mouseLeaveChoice"
    >
      <div
        :class="{
          'syncc-embeddable__choice-vertical': !this.isHorizontalVideo,
          'syncc-embeddable__choice-horizontal': this.isHorizontalVideo,
        }"
        class="syncc-embeddable__choice-plain-g241kNei"
        @click.prevent="handleConversion"
      >
        <play-original
          v-show="choice.choice_type == 'NEXT_PART'"
          :size="25"
          :color="playBtnColor"
        />
        <share-arrow v-show="choice.choice_type == 'EXTERNAL_LINK'" />
        <approved-message v-show="choice.choice_type == 'END'" />
        <span class="syncc-embeddable__choice-btn-content-plain-M12ln3sc">
          {{ choice.in_choice_text_content }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import ShareArrow from "./icons/svg/ShareArrow.vue";
import PlayOriginal from "./icons/svg/PlayOriginal.vue";
import ApprovedMessage from "./icons/svg/ApprovedMessage.vue";

export default {
  props: {
    choice: { type: Object, required: true },
    themeColor: { type: String, required: true },
    isHorizontalVideo: false,
  },
  components: {
    ShareArrow,
    PlayOriginal,
    ApprovedMessage,
  },
  data() {
    return {
      isMouseOverChoice: false,
      isMouseLeaveOverChoice: false,
      playBtnColor: "#fff",
      mainCTABtnTextColor: "",
    };
  },
  created() {
    if (this.choice.is_main_cta) {
      let rgbObj = this.hexToRGB(this.themeColor);
      this.mainCTABtnTextColor =
        rgbObj == null
          ? "#ffffff"
          : this.resolvedColorOf(rgbObj.r, rgbObj.g, rgbObj.b);
    }
  },
  methods: {
    resolvedColorOf(red, green, blue) {
      // see: https://stackoverflow.com/questions/3942878/how-to-decide-font-color-in-white-or-black-depending-on-background-color
      if (red * 0.299 + green * 0.587 + blue * 0.114 > 150) {
        return "#000000";
      } else {
        return "#ffffff";
      }
    },
    hexToRGB(hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    },
    mouseOverChoice() {
      this.isMouseOverChoice = true;
      this.isMouseLeaveOverChoice = false;
    },
    mouseLeaveChoice() {
      this.isMouseOverChoice = false;
      this.isMouseLeaveOverChoice = true;
      setTimeout(() => {
        this.isMouseLeaveOverChoice = false;
      }, 1000);
    },
    handleConversion() {
      this.$emit("on-process-update-watch-status");
      const chocieType = this.choice.choice_type;
      switch (chocieType) {
        case "NEXT_PART":
          this.$emit("on-process-choice-select", {
            eventType: this.choice.is_main_cta
              ? "CHOICE_SELECTED_MAIN_CTA_ENGAGED"
              : "CHOICE_SELECTED_VIDEO_PART_REQUEST",
            selectedChoiceLabel: this.choice.in_choice_text_content,
          });
          const nextPartId = this.choice.next_part;
          if (!nextPartId) {
            this.$router.push({ name: "thanks" });
            return;
          } else {
            this.$router.push({
              name: "story-videos",
              params: { id: nextPartId },
              query: { initial: false },
            });
            return;
          }
        case "EXTERNAL_LINK":
          this.$emit("on-process-choice-select", {
            eventType: this.choice.is_main_cta
              ? "CHOICE_SELECTED_MAIN_CTA_ENGAGED"
              : "CHOICE_SELECTED_EXTERNAL_LINK_ROUTED",
            selectedChoiceLabel: this.choice.in_choice_text_content,
          });
          const isQueryParamIncluded = this.hasQueryParamsOf(
            this.choice.link_url
          );
          const isEndsWithSlash = this.hasSlashEndsOf(this.choice.link_url);
          const linkWithSynccParam = isQueryParamIncluded
            ? this.choice.link_url + "&syncc-conversion=true"
            : isEndsWithSlash
            ? this.choice.link_url + "/?syncc-conversion=true"
            : this.choice.link_url + "?syncc-conversion=true";
          window.open(linkWithSynccParam, "_blank", "noopener");
          return;
        case "END":
          const choiceSelectEventPayload = {
            eventType: this.choice.is_main_cta
              ? "CHOICE_SELECTED_MAIN_CTA_ENGAGED"
              : "VIDEO_VIEW_COMPLETED",
            selectedChoiceLabel: this.choice.in_choice_text_content,
          };
          this.$emit("on-process-choice-select", choiceSelectEventPayload);
          this.$router.push({ name: "thanks" });
          return;
      }
    },
    hasQueryParamsOf(url) {
      return url.includes("?");
    },
    hasSlashEndsOf(url) {
      return url.endsWith("/");
    },
  },
};
</script>

<style scoped>
.syncc-embeddable__choice-btn-content-M12ln3sc {
  align-items: center;
  margin: 4px;
  color: #fff;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
}
.syncc-embeddable__choice {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 0px 12px 0px 16px;
  border-radius: 44px;
  box-sizing: border-box;
  color: white;
  transition: opacity 0.3s ease 0s;
  margin: 5px;
  justify-content: normal;
  min-width: 200px;
  max-width: 300px;
  flex: 1 1 0%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-height: 50px;
  height: fit-content;
}
</style>
