<template>
  <div>
    <h1>Error</h1>
  </div>
</template>

<script>
export default {
  mounted() {
    console.log("[syncc-embeddable] Error in mounted");
  },
  created() {
    console.log("[syncc-embeddable] Error in created");
  },
};
</script>